<template>
  <div class="employment" style="cursor:pointer">
    <!--头部组件-->
    <header_public></header_public>
    <!--内容-->
    <div class="employment_con">
      <Video_masks ref="video"></Video_masks>
      <div style="width: 100%;height: 16.27vw"></div>
      <div class="data_card">
        <div class="data_card_con con_style">
          <div class="con_title">
            <h6>积云大数据中心</h6>
            <p>积云就业大数据，就业情况实时播报</p>
          </div>
          <ul class="flex_style direction_row flex_nowrap justify_between">
            <li class="flex_style direction_column flex_nowrap justify_center align_center">
              <div class="data_card_title">
                <p class="text_color">本专业最高薪水</p>
              </div>
              <div
                class="data_card_nuber text_left flex_style direction_row flex_nowrap align_center"
              >
                <img src="../../assets/images/01-zgxz.png" alt />
                <div>
                  <span class="text_color">最高薪水</span>
                  <p class="text_color text_family">{{majorData.max_salary}}元</p>
                </div>
              </div>
            </li>
            <li class="flex_style direction_column flex_nowrap justify_center align_center">
              <div class="data_card_title">
                <p class="text_color">本专业平均薪资</p>
              </div>
              <div
                class="data_card_nuber text_left flex_style direction_row flex_nowrap align_center"
              >
                <img src="../../assets/images/01-pjxz.png" alt />
                <div>
                  <span class="text_color">平均薪资</span>
                  <p class="text_color text_family">{{majorData.avg_salary}}元</p>
                </div>
              </div>
            </li>
            <li class="flex_style direction_column flex_nowrap justify_center align_center">
              <div class="data_card_title">
                <p class="text_color">本专业薪水过万率</p>
              </div>
              <div
                class="data_card_nuber text_left flex_style direction_row flex_nowrap align_center"
              >
                <img src="../../assets/images/01-yxgw.png" alt />
                <div>
                  <span class="text_color">月薪过万</span>
                  <p class="text_color text_family">{{majorData.gt_wan_rate}}</p>
                </div>
              </div>
            </li>
            <li class="flex_style direction_column flex_nowrap justify_center align_center">
              <div class="data_card_title">
                <p class="text_color">本专业累计就业人数</p>
              </div>
              <div
                class="data_card_nuber text_left flex_style direction_row flex_nowrap align_center"
              >
                <img src="../../assets/images/01-jyrs.png" alt />
                <div>
                  <span class="text_color">就业人数</span>
                  <p class="text_color text_family">{{majorData.job_total}}人</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <ul class="mojor_name con_style flex_style direction_row flex_nowrap justify_between">
          <li
            :class="{active:categoryIndex===idx}"
            @click="clickCategory(idx)"
            v-for="(item,idx) in majors"
            :key="idx"
          >
            <p>{{item.major_name}}</p>
          </li>
        </ul>
      </div>
      <div class="mojor_from con_style">
        <table>
          <tr class="mojor_title">
            <th>班级</th>
            <th>姓名</th>
            <th>薪资</th>
            <th>户籍</th>
            <th>就业公司</th>
            <th>就业时间</th>
          </tr>
        </table>
        <div class="swiper-container swiper-job">
          <table class="swiper-wrapper">
            <tr class="mojor_con swiper-slide" v-for="(item, key) in offerList" v-bind:key="key">
              <td>{{item.class_name}}</td>
              <td>{{item.username}}</td>
              <td class="zsbj">{{item.official_salary}}</td>
              <td>{{item.province_name}} {{item.city_name}}</td>
              <td>{{item.company}}</td>
              <td>{{item.entry_date}}</td>
            </tr>
          </table>
        </div>
        <div class="table_bj">
          <img src="../../assets/images/xzbjzs.png" alt />
        </div>
        <div class="red_bj"></div>
      </div>
      <!--视频展播-->
      <div class="con_title">
        <h6>积云优秀学员视频展播</h6>
        <p>我们在积云成长在积云收获感恩遇见</p>
      </div>
      <div class="movies_video flex_style con_style justify_between">
        <div class="movies_videoLe" @click="$refs.video.checkVideoFun(stuExcellentList1.image)">
          <img :src="stuExcellentList1.imageThumb" alt />
          <div class="anniu">
            <img src="../../assets/images/bfbig_03.png" alt />
          </div>
          <div class="movies_decorate flex_style">
            <span
              v-if="stuExcellentList1.extend?stuExcellentList1.extend.tag:''"
            >{{stuExcellentList1.extend.tag}}</span>
            <h4>{{stuExcellentList1.title}}</h4>
            <p
              v-if="stuExcellentList1.extend?stuExcellentList1.extend.subtitle:''"
            >{{stuExcellentList1.extend.subtitle}}</p>
          </div>
        </div>
        <div class="movies_videoRi flex_style">
          <div
            class="movies_videoRiTo"
            v-for="(stu,key) in stuExcellentList2"
            v-bind:key="key"
            @click="$refs.video.checkVideoFun(stu.image)"
          >
            <img :src="stu.imageThumb" alt />
            <div class="anniu_small">
              <img src="../../assets/images/bfbig_03.png" alt />
            </div>
            <div class="movies_decorateSmall flex_style">
              <span v-if="stu.extend?stu.extend.tag:''">{{stu.extend.tag}}</span>
              <h4>{{stu.title}}</h4>
              <p v-if="stu.extend?stu.extend.subtitle:''">{{stu.extend.subtitle}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-movies con_style text_left">
        <!-- 轮播图 -->
        <div class="bannerWrap">
          <!-- style="width:100%;height:300px;" -->
          <!-- Swiper -->
          <div class="swiper-container swiper-videoCon">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide swiper-video"
                v-for="(stu, key) in stuExcellentList3"
                v-bind:key="key"
                @click="$refs.video.checkVideoFun(stu.image)"
              >
                <div class="interview_video">
                  <div class="interview_videoTop">
                    <img :src="stu.imageThumb" alt />
                    <div class="mask">
                      <img src="../../assets/images/bf.png" alt />
                    </div>
                  </div>
                  <div class="interview_videoBot flex_public">
                    <p>{{stu.title}}</p>
                    <span v-if="stu.extend?stu.extend.subtitle:''">{{stu.extend.subtitle}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-button-next swiper-button-white movies-button-next"></div>
        <div class="swiper-button-prev swiper-button-white movies-button-prev"></div>
      </div>
      <!--口碑-->
      <div class="con_title">
        <h6>金杯银杯不如学员口碑</h6>
        <p>用学员真实的就业信息回馈给讲师和家长</p>
      </div>
      <div class="evaluate">
        <ul class="flex_style direction_row flex_nowrap justify_around align_center">
          <li
            v-for="(stu,key) in stuVideoList"
            v-bind:key="key"
            @click="$refs.video.checkimgFun(stu.image)"
          >
            <img :src="stu.imageThumb?stu.imageThumb:stu.image" alt />
          </li>
        </ul>
      </div>
      <!-- 班级最新动态-->
      <div class="con_title">
        <h6>班级就业最新动态</h6>
        <p>快速、高效、一面而就</p>
      </div>
      <div class="dynamic">
        <ul class="flex_style direction_row flex_wrap justify_between">
          <li
            class="flex_style direction_column flex_nowrap align_center justify_center"
            v-for="(item,key) in pre_class"
            v-bind:key="key"
            :style="`background: url('${majorBgImage[item.major_name]}') no-repeat`"
          >
            <div class="stage">{{item.class_name}}</div>
            <div class="dynamic_con">
              <h5>{{item.major_name}}</h5>
              <span>学院平均就业薪水</span>
              <div>
                <i>￥</i>
                <p>{{item.avg_salary}}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!--全国就业-->
      <div class="con_title">
        <h6>学在北京，全国就业</h6>
        <p>立足北京，辐射全国各大一线、新一线城市</p>
      </div>
      <div class="work con_style flex_style align_center">
        <div class="work_data text_left">
          <span>
            {{not_in_bj}}
            <i>人</i>
          </span>
          <p>全国各地累计就业人数</p>
          <ul>
            <li class="flex_style align_center">
              <span></span>
              <p>就业网络覆盖全国</p>
            </li>
            <li class="flex_style align_center">
              <span></span>
              <p>超5000家合作企业提供岗位需求</p>
            </li>
            <li class="flex_style align_center">
              <span></span>
              <p>学员就业无忧</p>
            </li>
          </ul>
        </div>
        <img src="../../assets/images/quxdu.png" alt />
      </div>
      <!--合作企业-->
      <div class="con_title">
        <h6>优质合作企业一览</h6>
        <p>致力于向全国各地各大互联网公司持续输送人才</p>
      </div>
      <enterprise :num="5" :limit="10" class="cooperation"></enterprise>
    </div>
    <!--申请试听-->
    <Apply_form></Apply_form>
    <!--底部-->
    <bottom_public></bottom_public>
  </div>
</template>

<script>
import bottom_public from "../../components/bottom_public/bottom_public";
import header_public from "../../components/header_public/header_public";
import Swiper from "swiper/js/swiper.min.js";
import "swiper/css/swiper.min.css";
import Enterprise from "../../components/enterprise/enterprise";
import Apply_form from "../../components/apply/apply_form";
import Video_masks from "../../components/video_masks/video_masks";

const axios = require("axios").default;

export default {
  metaInfo: {
    title: "高薪就业-[积云教育]",
    meta: [
      {
        name: "description",
        content:
          "本栏目对积云教育毕业的学员就业、平均薪资水平做了简单的介绍，方便学员们查看以作参考。",
      },
      {
        name: "keywords",
        content: "高薪就业",
      },
    ],
  },
  components: {
    Video_masks,
    Apply_form,
    Enterprise,
    bottom_public,
    header_public,
  },
  data() {
    return {
      menu: ["人工智能", "大数据", "新媒体", "物联网", "全栈开发"],
      majorBgImage: {
        大数据: require("../../assets/images/dsjkp.png"),
        人工智能: require("../../assets/images/rgznkp.png"),
        新媒体: require("../../assets/images/sjsjkp.png"),
        视觉设计: require("../../assets/images/sjsjkp.png"),
        新媒体视觉: require("../../assets/images/sjsjkp.png"),
        物联网: require("../../assets/images/wlwkp.png"),
        前端云计算: require("../../assets/images/ydjskp.png"),
        全栈: require("../../assets/images/qzkp.png"),
        全栈开发: require("../../assets/images/qzkp.png"),
      },
      index: 0,
      categoryIndex: 0,
      not_in_bj: 0,
      majors: [],
      majorData: {},
      pre_class: [],
      stuVideoList: [],
      stuExcellentList1: {},
      stuExcellentList2: [],
      stuExcellentList3: [],
      offerList: [],
      swiperJob: null,
    };
  },
  created() {
    this.getSalaryData();
    this.getStuVideo();
    this.getStuExcellent();
  },
  methods: {
    clickCategory(idx) {
      this.categoryIndex = idx;
      var that = this;
      var major_name = "大数据";
      this.majors.forEach(function (item, index) {
        if (idx === index) {
          that.majorData = item;
          major_name = item.major_name;
        }
      });
      this.getOfferList(major_name);
    },
    getSalaryData() {
      axios.get("/api/job/getSalaryData").then(({ data: { data = {} } }) => {
        if (data.pre_major) {
          this.not_in_bj = data.not_in_bj;
          this.majors = data.pre_major;
          this.clickCategory(0);
          this.pre_class = data.pre_class;
        }
      });
    },
    getOfferList(major_name) {
      var limit = 50;
      axios
        .get(
          "/api/job/getJobList?limit=" +
            limit +
            "&major_name=" +
            encodeURIComponent(major_name)
        )
        .then(({ data: { data = [] } }) => {
          this.offerList = data;
          this.$nextTick(() => {
            this.swiperJob && this.swiperJob.destroy();
            this.swiperJob = new Swiper(".swiper-job", {
              slidesPerView: 12,
              // spaceBetween: 32,
              direction: "vertical", // 垂直切换选项
              autoplay: {
                delay: 800,
              }, // 自动播放
              loop: true, // 循环模式选项
            });
          });
        });
    },
    getStuVideo() {
      axios
        .get("/api/block/getItemsByName?name=" + encodeURIComponent("学员口碑"))
        .then(({ data: { data = [] } }) => {
          this.stuVideoList = data;
        });
    },
    getStuExcellent() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("优秀学员") +
            "&type=" +
            encodeURIComponent("视频")
        )
        .then(({ data: { data = [] } }) => {
          for (var i = 0; i < data.length; i++) {
            if (i === 0) {
              this.stuExcellentList1 = data[i];
            } else if (i === 1 || i === 2) {
              this.stuExcellentList2.push(data[i]);
            } else {
              this.stuExcellentList3.push(data[i]);
            }
          }
        });
    },
  },
  mounted() {
    // chrome
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
    // safari
    // window.pageYOffset = 0;
    new Swiper(".swiper-videoCon", {
      slidesPerView: 4,
      slidesPerGroup: 4,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false, // 取消鼠标操作后的轮播暂停【无操作轮播继续】【参考链接1】
        stopOnLastSlide: false, // 在最后一页停止
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".movies-button-next",
        prevEl: ".movies-button-prev",
      },
    });
  },
};
</script>

<style lang="scss" scoped>
#header {
  position: fixed;
  background: linear-gradient(
    90deg,
    rgba(193, 48, 252, 1),
    rgba(120, 23, 241, 1)
  );
  opacity: 0.99;
}

.text_left {
  text-align: justify !important;
}

.text_family {
  font-family: PingFang;
}

.text_color {
  color: rgba(28, 26, 91, 1) !important;
}

.con_style {
  width: 62.5vw !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.flex_style {
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.direction_row {
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row;
}

.direction_column {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.flex_wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex_nowrap {
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.align_flex-start {
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  -o-align-items: flex-start;
  align-items: flex-start;
}

.align_center {
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
}

.align_end {
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  -o-align-items: flex-end;
  align-items: flex-end;
}

.justify_start {
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  -o-justify-content: flex-start;
  justify-content: flex-start;
}

.justify_end {
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -o-justify-content: flex-end;
  justify-content: flex-end;
}

.justify_center {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
}

.justify_around {
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  -o-justify-content: space-around;
  justify-content: space-around;
}

.justify_between {
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
}

.employment_con {
  width: 100%;
  height: 323.958vw;
  background: url("../../assets/images/gxjydbj.jpg") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  box-sizing: border-box;
  /*padding-top: 34vw;*/
  position: relative;
  margin-top: 5.4vw;
}

.data_card {
  width: 100%;
  // height: 30.979vw;
}

.data_card_con ul li {
  width: 15.208vw;
  height: 22.343vw;
  background: url("../../assets/images/gxjykp2.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: 2.875rem;
}

#test {
  width: 100%;
  height: 100%;
  display: none !important;
}

.data_card_title {
  margin-bottom: 30px;
}

.data_card_title p {
  font-size: 1.75rem;
  font-family: SourceHanSansCN;
  font-weight: bold;
}

.charts {
  width: 13.854vw;
  height: 10.885vw;
  margin: 0 auto;
}

.data_card_nuber {
  width: 80%;
}

.data_card_nuber img {
  width: 6.041vw;
  height: 6.041vw;
}

.data_card_nuber div {
  margin-left: -5px;
}

.data_card_nuber div span {
  font-size: 1rem;
  font-family: SourceHanSansCN;
  font-weight: 500;
  line-height: 2.5rem;
}

.data_card_nuber div p {
  font-size: 1.5rem;
  font-weight: 800;
}

.mojor_name {
  margin-top: 4.6875vw;
}

.mojor_name li {
  width: 10.145vw;
  height: 3.234vw;
  background: linear-gradient(
    -38deg,
    rgba(92, 32, 168, 1),
    rgba(54, 72, 215, 1)
  );
  border-radius: 1.9375rem;
  cursor: pointer;
}

.active {
  background: linear-gradient(
    167deg,
    rgba(104, 26, 133, 1),
    rgba(251, 94, 116, 1)
  ) !important;
}

.mojor_name li p {
  font-size: 1.75rem;
  font-family: SourceHanSansCN;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  line-height: 3.234vw;
}

.mojor_from {
  // height: 40.297vw;
  margin-top: 4.604vw;
  position: relative;
}

.mojor_from table {
  width: 100%;
  font-size: 1.125rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(78, 78, 78, 1);
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.mojor_from table th,
.mojor_from table td {
  width: 10.4vw;
  height: 3.4vw;
}

.mojor_from div.swiper-job {
  height: 40vw;
}
.mojor_from div.swiper-job table {
  border-radius: 0;
}

.mojor_title {
  width: 100%;
  height: 4.354vw;
  background-color: #dd3648 !important;
  font-size: 1.5rem;
  font-family: FZLTCHK;
  font-weight: normal;
  color: rgba(255, 255, 255, 1);
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 20px 20px 0px 0px;
}

.mojor_title th {
  width: 16.66%;
}

.con_style th {
  text-align: center;
}

.con_style th:first-child {
  border-radius: 10px 0px 0px 0px;
}

.con_style th:last-child {
  border-radius: 0px 10px 0px 0px;
}

.mojor_con {
  width: 100%;
  height: 3.3vw;
}

.mojor_from table tr:nth-child(even) {
  background: rgba(255, 255, 255, 1);
}

.mojor_from table tr:nth-child(odd) {
  background-color: #eeeeee;
}

.table_bj {
  width: 12.083vw;
  height: 1.093vw;
  position: absolute;
  top: -1vw;
  left: 20vw;
}

.table_bj img {
  width: 100%;
  height: 100%;
}

.zsbj {
  font-size: 1.875rem;
  font-family: SourceHanSansCN;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  background-color: #dd3648 !important;
}

.red_bj {
  width: 16.7665%;
  height: 1.041vw;
  background-color: #dd3648;
  position: absolute;
  bottom: -0.9vw;
  left: 20.7vw;
}

h6 {
  font-size: 2.5rem;
  font-family: SourceHanSansCN;
  font-weight: 800;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0.175rem;
}

.con_title {
  margin: 5.468vw 0 2.125vw;
}

.con_title p {
  font-size: 1.125rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: rgba(255, 255, 255, 1);
  line-height: 2.7rem;
}

.movies_videoLe {
  width: 41.114vw; //799px
  height: 23.333vw; //448px
  position: relative;
}

.anniu,
.anniu_small,
.anniu_smallBo {
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 1);
  // opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(0, 0, 0, .5);
}

.anniu_small,
.anniu_smallBo {
  height: 11.24vw;
}

.anniu img {
  width: 5.937vw !important; //114px
  height: 5.937vw !important; //114px
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

.movies_videoLe img,
.movies_videoRiTo img,
.movies_videoRiBo img {
  width: 100%;
  height: 100%;
}

.movies_decorate,
.movies_decorateSmall {
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  bottom: 6px;
  left: 10px;
}

.movies_decorate span,
.movies_decorateSmall span {
  display: block;
  font-size: 0.7rem;
  line-height: 1.1979vw;
  color: $usian-bg-color;
  background: rgba(38, 38, 38, 1);
  padding: 5px 4px;
}

.movies_decorate h4,
.movies_decorateSmall h4 {
  font-size: 1.75rem;
  font-weight: bold;
  color: $usian-bg-color;
  margin: 0.5625rem 0;
}

.movies_decorateSmall h4 {
  font-size: 1.25rem;
}

.movies_decorate p,
.movies_decorateSmall p {
  font-size: 0.875rem;
  font-weight: bold;
  color: $usian-bg-color;
}

.anniu_small img,
.anniu_smallBo img {
  width: 1.718vw;
  height: 1.718vw;
  position: absolute;
  top: 20px;
  right: 20px;
}

.movies_videoRi {
  width: 20.765vw; //398.7px
  // position: relative;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.movies_videoRiTo,
.movies_videoRiBo {
  width: 100%;
  height: 11.24vw; //223.5px
  position: relative;
}

.swiper-video {
  width: 15.22vw !important;
  height: 12.937vw !important;
  margin-right: 0.6rem !important;
}

.interview_videoTop {
  width: 100%;
  height: 8.177vw;
  position: relative;
}

.interview_videoTop img {
  width: 100%;
  height: 100%;
}

.mask {
  width: 2.447vw;
  height: 2.447vw;
  position: absolute;
  bottom: 0.5vw;
  right: 1vw;
}

.mask img {
  width: 100%;
  height: 100%;
}

.interview_videoBot {
  height: 2.864vw;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 0.875rem;
}

.interview_videoBot p {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
}

.interview_videoBot span {
  font-size: 0.875rem;
  font-weight: 400;
  color: #ffffff;
}

.swiper-movies {
  margin-top: 1.1458vw;
  position: relative;
}

.movies-button-next,
.movies-button-prev {
  border-radius: 50%;
  width: 2.604vw;
  height: 2.604vw;
  background-color: #000000;
  opacity: 1 !important;
}

.movies-button-next,
.movies-button-prev {
  font-size: 1.125rem !important;
  outline: none;
  top: 38% !important;
  opacity: 1;
}

.movies-button-prev {
  left: -4.25rem !important;
}

.movies-button-next {
  right: -4.25rem !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1.125rem;
}

.evaluate ul li {
  width: 14.01vw;
  height: 29.427vw;
  background: url("../../assets/images/03-phone-x.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: relative;
}

.evaluate ul li img {
  width: 11.822vw;
  height: 21.041vw;
  position: absolute;
  left: 1.2vw;
  top: 4.3vw;
}

.big_phone {
  width: 15.468vw !important;
  height: 32.448vw !important;
  background: url("../../assets/images/03-phone-d.png") no-repeat !important;
  background-size: 100% 100% !important;
  -moz-background-size: 100% 100% !important;
}

.big_phone img {
  width: 13.02vw !important;
  height: 23.177vw !important;
  left: 1.35vw !important;
  top: 4.75vw !important;
}

.dynamic {
  width: 47vw;
  margin: 0 auto;
}

.dynamic ul li {
  width: 14.895vw;
  height: 17.968vw;
  box-sizing: border-box;
  padding-top: 3.25rem;
}

.dynamic ul li:nth-child(1) {
  background-size: 100% 100% !important;
  -moz-background-size: 100% 100% !important;
  margin-bottom: 1.406vw;
}

.dynamic ul li:nth-child(2) {
  background-size: 100% 100% !important;
  -moz-background-size: 100% 100% !important;
  margin-bottom: 1.406vw;
}

.dynamic ul li:nth-child(3) {
  background-size: 100% 100% !important;
  -moz-background-size: 100% 100% !important;
  margin-bottom: 1.406vw;
}

.dynamic ul li:nth-child(4) {
  background-size: 100% 100% !important;
  -moz-background-size: 100% 100% !important;
}

.dynamic ul li:nth-child(5) {
  background-size: 100% 100% !important;
  -moz-background-size: 100% 100% !important;
}

.dynamic ul li:nth-child(6) {
  background-size: 100% 100% !important;
  -moz-background-size: 100% 100% !important;
}

.stage {
  width: 11.458vw;
  height: 2vw;
  font-size: 1.125rem;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: rgba(27, 27, 27, 1);
  border-bottom: 1px dashed #b5b5b5;
  margin-bottom: 0.625rem;
}

.dynamic_con h5 {
  font-size: 1.875rem;
  font-family: SourceHanSansCN;
  font-weight: bold;
  color: rgba(27, 27, 27, 1);
  margin: 0.3125rem 0;
}

.dynamic_con span {
  font-size: 1.125rem;
  font-family: SourceHanSansCN;
  font-weight: bold;
  color: rgba(102, 102, 102, 1);
}

.dynamic_con div {
  position: relative;
}

.dynamic_con div i {
  font-size: 1.5625rem;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: rgba(255, 77, 89, 1);
  position: absolute;
  top: 0.95vw;
  left: 0;
}

.dynamic_con div p {
  font-size: 3.375rem;
  font-family: SourceHanSansCN;
  font-weight: bold;
  color: rgba(255, 77, 89, 1);
  margin-top: 0.625rem;
  display: inline-block;
  text-indent: 1.125rem;
}

.work {
  height: 21.44vw;
}

.work_data {
  width: 22.448vw;
  height: 17.448vw;
  color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  padding: 2.708vw 0 0 3.229vw;
  background: linear-gradient(
    123deg,
    rgba(211, 44, 253, 1) 0%,
    rgba(255, 70, 161, 1) 100%
  );
}

.work img {
  width: 39.68vw;
  height: 27.448vw;
}

.work_data span {
  font-size: 4.375rem;
  font-family: SourceHanSansCN;
  font-weight: bold;
}

.work_data span i {
  font-size: 2.1875rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  vertical-align: middle;
}

.work_data > p {
  font-size: 1.4375rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  margin: 0px 0 0.8vw;
}

.work_data ul li {
  margin-bottom: 0.3vw;
}

.work_data ul li span {
  display: inline-block;
  width: 0.5625vw;
  height: 0.5625vw;
  background-color: #ffffff;
  border-radius: 50%;
  margin-right: 5px;
}

.work_data ul li p {
  display: inline-block;
  font-size: 1.1875rem !important;
  font-family: Adobe Heiti Std;
  font-weight: normal !important;
}

.audition {
  height: 23vw;
  position: absolute;
  left: 21rem;
  bottom: 0;
}

.from_con h1 {
  font-size: 3.75rem;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.from_con h1 i {
  color: #ff3ea6 !important;
}

.from_con > p {
  font-size: 1.5rem;
  font-family: SourceHanSansCN;
  font-weight: 200;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 20px;
}

.from_con form div {
  width: 21.458vw;
  height: 3.108vw;
  background-color: rgb(87, 25, 134);
  margin-bottom: 5px;
}

.from_con form div p {
  display: inline-block;
  width: 34%;
  height: 1.802vw;
  font-size: 1.5rem;
  font-family: SourceHanSansCN;
  font-weight: 200;
  color: rgba(171, 138, 232, 1);
  border-right: 1px solid #ab8ae8;
  text-align-last: justify;
  box-sizing: border-box;
  padding: 0 10px;
}

.from_con form > input {
  width: 14.531vw;
  height: 3.177vw;
  background: #f91f95;
  border-radius: 11px;
  border: 0px;
  outline: none;
  margin-top: 20px;
}

.from_con form div input {
  width: 62%;
  height: 100%;
  color: rgba(171, 138, 232, 1);
  border: 0px;
  outline: none;
  background-color: rgb(87, 25, 134);
}

.slogan p {
  font-size: 4.5rem;
  font-family: SourceHanSerifCN;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.foot_box {
  margin-top: 0 !important;
}

@media screen and (max-width: 1366px) {
  .data_card_title p {
    font-size: 21px;
  }
}

@media screen and (max-width: 1242px) {
  .data_card_title p {
    font-size: 19px;
  }

  .data_card_nuber div {
    margin-left: -10px;
  }
}

@media screen and (max-width: 1200px) {
  .data_card_title p {
    font-size: 19px;
  }

  .data_card_nuber div {
    margin-left: -10px;
  }
  // .movies_decorate span {
  //     width: 2.2vw;
  // }
  // .movies_decorateSmall span {
  //     width: 2.2vw;
  // }
}

@media screen and (max-width: 1125px) {
  .data_card_title p {
    font-size: 17px;
  }

  .data_card_nuber div {
    margin-left: -14px;
  }
  // .movies_decorate span {
  //     width: 2.4vw;
  // }
  // .movies_decorateSmall span {
  //     width: 2.4vw;
  // }
  .dynamic_con div i {
    left: 0.5vw;
  }
}

@media screen and (max-width: 1024px) {
  .data_card_title p {
    font-size: 16px;
  }

  .data_card_nuber div {
    margin-left: -10px;
  }
  // .movies_decorate span {
  //     width: 2.5vw;
  // }
  // .movies_decorateSmall span {
  //     width: 2.5vw;
  // }
  .dynamic_con div i {
    left: 0.9vw;
  }
  .work_data[data-v-1d9340c5] {
    padding: 1.708vw 0 0 2.229vw;
  }
  .work_data ul li {
    margin-bottom: 0;
  }
}
</style>
